import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import axios from "axios";
const routes = [
  {
    path: "/",
    name: "Home2",
    component: Home,
    redirect: "/app",
  },
  {
    path: "/app",
    name: "Home",
    component: Home,
    children: [
      {
        path: "development/list/:status?/:page?/:search?",
        name: "DevelopmentList",
        component: () => import("@/views/Development/List.vue"),
      },
      {
        path: "development/chat/:id",
        name: "DevelopmentChat",
        component: () => import("@/views/Development/Chat.vue"),
      },
      {
        path: "development/form/:id",
        name: "DevelopmentForm",
        component: () => import("@/views/Development/Add.vue"),
      },
      {
        path: "test/list/:status?/:page?/:search?",
        name: "TestingList",
        component: () => import("@/views/Testing/List.vue"),
      },
      {
        path: "test/chat/:id",
        name: "TestingChat",
        component: () => import("@/views/Testing/Chat.vue"),
      },
      {
        path: "test/form/:id",
        name: "TestingForm",
        component: () => import("@/views/Testing/Add.vue"),
      },
      {
        path: "statistic",
        name: "Statistic",
        component: () => import("@/views/Statistic/Statistic.vue"),
      },
      {
        path: "sales",
        name: "Sales Achievement",
        component: () => import("@/views/Statistic/Sales.vue"),
      },
      {
        path: "notification",
        name: "Notification",
        component: () => import("@/views/Notification/List.vue"),
      },
      {
        path: "pengaturan",
        name: "Pengaturan",
        component: () => import("@/views/Pengaturan/Pengaturan.vue"),
      },
    ],
  },

  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach(async (to, from, next) => {
  if (to.name == "Login") {
    next();
  } else {
    let token = localStorage.getItem("token");
    let payload = {
      token: token,
    };
    try {
      let resp = await axios.post(
        `${process.env.VUE_APP_BASE_URL_RND}/user_data`,
        payload
      );
      to.meta.user = resp.data;
      next();
    } catch (error) {
      console.log("gagal menarik data user");
      localStorage.clear();
      next("/login");
    }
  }
});

export default router;
