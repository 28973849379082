<template>
  <q-layout class="bg-grey-6" view="lHh Lpr lFf">
    <router-view class="l-container" />
  </q-layout>
</template>

<script>
import { ref } from "vue";

export default {
  components: {},

  setup() {
    return {};
  },
};
</script>
<style lang="scss">
@import "@/styles/quasar.scss";
.stickyTable {
  height: 300px;

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    background-color: $indigo-5;
  }
  thead tr th {
    position: sticky;
    z-index: 1;
  }
  thead tr:first-child th {
    top: 0;
  }
  &.q-table--loading thead tr:last-child th {
    top: 48px;
  }
}
.l-container {
  height: 100vh;
  // width: 100vw;
}
.l-mobile-container {
  max-width: 1024px;
  min-width: 300px;

  margin: 0 auto;
}
.l-wrapper {
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.l-grow {
  flex-grow: 99;
}
</style>
