import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import Dialog from 'quasar/src/plugins/Dialog.js';;
import quasarUserOptions from "./quasar-user-options";
import CKEditor from "@ckeditor/ckeditor5-vue";
import VueSplash from "vue-splash";
import money from "v-money3";

import axios from "axios";

let app = createApp(App);
app
  .component("splash", VueSplash)
  .use(Quasar, {
    plugins: {
      Notify,
      Loading,
      Dialog,
    },
    config: {
      loading: {},
    },
    quasarUserOptions,
  })
  .use(store)
  .use(router)
  .use(money)
  .use(CKEditor);

app.provide(
  "$http",
  axios.create({
    baseURL: process.env.VUE_APP_BASE_URL_RND,
  })
);
app.provide("rupiah", (number) => {
  return Intl.NumberFormat("id", {
    style: "currency",
    currency: "IDR",
    maximumSignificantDigits: 2,
  }).format(number);
});

app.provide("thousand", (number) => {
  return Intl.NumberFormat("id").format(number);
});

app.directive("can", {
  beforeMount(el, binding, vnode) {
    // vuex diisi saat sukses login
    let role = binding.instance.route.meta.user;

    if (binding.value.includes("SALES") && role.is_sales == "1") {
      return;
    } else if (binding.value.includes("ADMIN") && role.is_admin == "1") {
      return;
    } else if (binding.value.includes("MANAGER") && role.is_manager == "1") {
      return;
    } else if (
      binding.value.includes("PURCHASING") &&
      role.is_purchasing == "1"
    ) {
      return;
    } else if (binding.value.includes("RND") && role.is_rnd == "1") {
      return;
    } else if (
      binding.value.includes("RND_SUPERVISOR") &&
      role.is_rnd_supervisor == "1"
    ) {
      return;
    } else {
      el.style.display = "none";
    }
  },
});

app.mount("#app");
