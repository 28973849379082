<template>
  <div>
    <q-header elevated class="">
      <q-toolbar>
        <q-btn
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="menu"
        />

        <q-toolbar-title>
          {{ route.name }}
        </q-toolbar-title>
        <q-btn
          flat
          dense
          color="white"
          icon="notifications"
          to="/app/notification"
        >
          <q-badge v-if="countNotif > 0" color="red" floating>{{
            countNotif
          }}</q-badge>
        </q-btn>
      </q-toolbar>
    </q-header>

    <q-drawer
      v-model="leftDrawerOpen"
      show-if-above
      bordered
      class="bg-grey-2 column justify-between"
    >
      <div>
        <img
          class="q-px-md"
          style="padding-top: 5.2%; width:100%"
          :src="`../img/fortebond.png`"
          spinner-color="white"
        />
        <div class="my-font text-bold text-center q-my-sm text-body1">
          Research & Development App
        </div>
        <q-separator></q-separator>
        <q-list separator>
          <q-item class="row items-center">
            <q-item-section top avatar>
              <q-avatar color="grey" text-color="white" icon="person" />
            </q-item-section>

            <q-item-section>
              <q-item-label class="text-bold text-h6">{{
                route.meta.user.username
              }}</q-item-label>
              <q-btn
                flat
                dense
                align="left"
                label="LOGOUT"
                color="negative"
                @click="logout"
              ></q-btn>
            </q-item-section>
          </q-item>
          <q-item
            clickable
            v-ripple
            to="/app/development/list"
            v-can="['MANAGER', 'RND', 'SALES', 'ADMIN']"
          >
            <q-item-section>
              <q-item-label>Development</q-item-label>
            </q-item-section>
          </q-item>
          <q-item
            clickable
            v-ripple
            to="/app/test/list"
            v-can="['MANAGER', 'RND', 'PURCHASING']"
          >
            <q-item-section>
              <q-item-label>Testing Material</q-item-label>
            </q-item-section>
          </q-item>
          <q-item
            clickable
            v-ripple
            to="/app/statistic"
            v-can="['MANAGER', 'RND']"
          >
            <q-item-section>
              <q-item-label>Statistic</q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-ripple to="/app/sales" v-can="['SALES']">
            <q-item-section>
              <q-item-label>Sales Achievement</q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-ripple v-can="['ADMIN']" @click="downloadSummary">
            <q-item-section>
              <q-item-label>Excel</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
      <div>
        <q-separator></q-separator>
        <q-item clickable v-ripple to="/app/pengaturan">
          <q-item-section>
            <q-item-label class="row items-center"
              ><q-icon
                class="q-mr-sm text-grey-8"
                name="settings"
                style="font-size: 1.6em;"
              />
              PENGATURAN
            </q-item-label>
          </q-item-section>
        </q-item>
      </div>
    </q-drawer>

    <q-page-container class="bg-grey-6 l-mobile-container column">
      <div class="home"></div>
      <router-view class="l-grow"></router-view>
    </q-page-container>
  </div>
</template>

<script>
// @ is an alias to /src
import { inject, onBeforeUnmount, onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";
import { useQuasar } from "quasar";
export default {
  name: "Home",
  components: {},
  setup() {
    let router = useRouter();
    let route = useRoute();
    const $http = inject("$http");
    const $q = useQuasar();
    let logout = () => {
      localStorage.clear();
      $q.notify({
        message: "Proses Logout Berhasil",
        color: "positive",
      });
      router.push("/login");
    };

    let countNotif = ref(0);
    let idInterval = null;
    onMounted(async () => {
      await getNotif();
      idInterval = window.setInterval(async () => {
        await getNotif();
      }, 5000);
    });
    onBeforeUnmount(() => {
      window.clearInterval(idInterval);
    });

    let getNotif = async () => {
      let resp = await $http.get(`/notification/count/${route.meta.user?.id}`);
      countNotif.value = resp.data?.banyak;
    };

    let downloadSummary = async () => {
      // const ExcelJS = require("exceljs");
      const workbook = new ExcelJS.Workbook();
      const sDevelopment = workbook.addWorksheet("DEVELOPMENT REQUEST");
      const sTest = workbook.addWorksheet("TEST REQUEST");
      let payload = {
        start: "2021-01-01",
        end: "2021-12-01",
      };
      let resp = await $http.post("/summary/excel", payload);
      let listDevelopment = resp.data[0];
      let count = 1;
      for (let k in listDevelopment) {
        // sDevelopment.addRow(Object.values(listDevelopment[k]));
        let x = listDevelopment[k];
        sDevelopment.addRow([
          count++,
          x.sales_name,
          x.rnd_name,
          x.customer_name,
          x.name,
          moment(x.created).format("DD-MMM-YYYY"),
          moment(x.responded).format("HH:MM DD-MMM-YYYY"),
          moment(x.development_end).format("DD-MMM-YYYY"),
          moment(x.observation_end).format("DD-MMM-YYYY"),
          x.type,
          x.qty,
          x.target_price,
          x.request_status,
          x.sales_status,
        ]);
      }
      let listTest = resp.data[1];
      for (let k in listTest) {
        sTest.addRow(Object.values(listTest[k]));
      }

      const buffer = await workbook.xlsx.writeBuffer();
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const blob = new Blob([buffer], { type: fileType });
      saveAs(blob, "newExcelSummary.xlsx");
    };

    return {
      logout,
      route,
      leftDrawerOpen: ref(false),
      countNotif,
      downloadSummary,
    };
  },
};
</script>
<style lang="scss">
@font-face {
  font-family: customfont;
  src: url(../css/fonts/Monopola.ttf);
}

.my-font {
  font-family: "customfont";
}
</style>
